// extracted by mini-css-extract-plugin
export var article = "faq-module--article--58550";
export var bendableGold = "faq-module--bendableGold--f3cc8";
export var carouselSlide = "faq-module--carouselSlide--315fe";
export var dateline = "faq-module--dateline--80d8b";
export var faqBody = "faq-module--faqBody--6387f";
export var faqHeading = "faq-module--faqHeading--3ad99";
export var faqItem = "faq-module--faqItem--4142c";
export var headingLogo = "faq-module--headingLogo--45e13";
export var latestnewsarticleheadline = "faq-module--latestnewsarticleheadline--95299";
export var main = "faq-module--main--931ad";
export var subhead = "faq-module--subhead--e0e54";
export var twoLineHeader = "faq-module--twoLineHeader--baad6";